import React from 'react';
import moment from 'moment';
import { renderSafeValue } from 'utils';
import { KYC_FRACTAL_LINK } from 'constants/config';
import InfoContainer from '../../../../../components/InfoContainer';
import './index.scss';

const KycInfo = ({ user }) => {
  if (!user) return null;

  console.log('temp deployment cl');

  const kycInfo = [
    {
      label: 'Full KYC Data',
      value: user.kycData.fractalId ? (
        <a
          href={`${KYC_FRACTAL_LINK}${user.kycData.fractalId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
      ) : (
        '-'
      ),
    },
    { label: 'Name', value: user.kycData.name },
    { label: 'First Name', value: user.kycData.firstName },
    { label: 'Last Name', value: user.kycData.lastName },
    { label: 'Email', value: user.kycData.email },

    { label: 'Document Type', value: user.kycData.documentType },
    { label: 'Document Number', value: user.kycData.documentNumber },
    { label: 'Country', value: user.kycData.country },

    { label: 'KYC Status', value: user.kycStatus },
    {
      label: 'KYC Date',
      value: moment(user.kycData.kycDate).format('DD MMM YYYY'),
    },

    { label: 'Date of Birth', value: moment(user.kycData.dateOfBirth).format('DD MMM YYYY') },
    { label: 'Place of Birth', value: user.kycData.placeOfBirth },
    { label: 'Residential Address', value: user.kycData.residentialAddress },
    { label: 'Residential Address Country', value: user.kycData.residentialAddressCountry },
  ];

  return (
    <>
      <InfoContainer title="KYC DATA">
        <ul className="item-list">
          {kycInfo.map((info) => (
            <li key={info.label} className="item">
              <p className="label">{info.label}</p>
              <p className="value">{renderSafeValue(info.value)}</p>
            </li>
          ))}
        </ul>
      </InfoContainer>
    </>
  );
};

export default KycInfo;
