import { useQuery } from 'react-query';
import { getKYCStatus } from 'services/apiService';

function useKYCStatusQuery({ onSuccess, onError }) {
  const { data, refetch } = useQuery(['kyc-status'], getKYCStatus, {
    onSuccess,
    onError,
    enabled: false,
  });

  return { data, refetchKYCStatus: refetch };
}

export default useKYCStatusQuery;
