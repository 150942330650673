import React, { useState } from 'react';
import SearchInput from 'components/filters/SearchInput';
import useFilters from '../../hooks/useFilters';
import './index.scss';
import UsersLegalTable from '../UsersLegalTable';
import KycSyncButton from '../KycSyncButton';

const applySearchFilter = (search, user) => {
  if (!search || search.length < 3) return true;
  const userName = user?.name?.toLowerCase();

  const userWallet = user?.wallet?.toLowerCase();

  return (
    (userName && userName.includes(search.toLowerCase())) ||
    (userWallet && userWallet.includes(search.toLowerCase()))
  );
};

const filtersPipeline = (users, filters) => {
  if (!users) return [];

  const applyFilters = [(user) => applySearchFilter(filters.search, user)];

  const filterResult = applyFilters.reduce(
    (filteredUsers, filterFunc) => filteredUsers.filter(filterFunc),
    users
  );

  return filterResult;
};

const UsersLegalContent = ({ users }) => {
  const { filters, actions } = useFilters(users);
  const filteredUsers = filtersPipeline(users, filters);
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <div className="deals-header d-flex">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Users</div>
          {users && (
            <div className="deals-header__deals-cnt vertical-center total-count">
              <span>{users.length} Total</span>
            </div>
          )}
        </div>
        <KycSyncButton />
        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={filters.search}
              updateSearchValue={actions.dispatchUpdateSearch}
              showInput={showInput}
              setShowInput={setShowInput}
              placeholder="Search users by name or address"
            />
          </div>
        </div>
      </div>

      <UsersLegalTable users={filteredUsers} filters={filters} />
    </>
  );
};

export default UsersLegalContent;
