import React, { useEffect, useState } from 'react';
import { Synaps } from '@synaps-io/verify-sdk';
import RoundedButton from 'components/button/rounded-button';
import { getSynapsSessionId } from 'services/apiService';
import { useNotifications } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { updateAuth } from 'store/actions';
import useKYCStatusQuery from './hooks/useKYCStatusQuery';
import './index.scss';

const SynapsModal = () => {
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);

  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const authReducer = useSelector((state) => state.auth);

  const { refetchKYCStatus } = useKYCStatusQuery({
    onSuccess: ({ isVerified }) => {
      if (!isVerified) {
        return;
      }

      dispatch(updateAuth({ idVerified: 'verification_approved' }));
      showSuccessNotification('KYC Successful');
    },
    onError: () => {
      showErrorNotification('Failed to check KYC status');
    },
  });

  useEffect(() => {
    if (!sessionId) {
      return;
    }

    Synaps.init({
      sessionId,
      service: 'individual',
      onFinish: refetchKYCStatus,
      mode: 'modal',
      onClose: () => {
        document.body.style.overflow = 'auto';
      },
    });
  }, [sessionId, refetchKYCStatus]);

  const onVerify = async () => {
    document.body.style.overflow = 'hidden';

    const sessionId = await getSynapsSessionId();

    setSessionId(sessionId);

    setTimeout(() => {
      Synaps.show();
    }, 100);
  };

  return (
    <RoundedButton type="primary" onClick={onVerify}>
      {!authReducer.idVerified ? 'Verify' : 'Re-verify'}
    </RoundedButton>
  );
};

export default SynapsModal;
