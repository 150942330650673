import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import useKycSyncMutation from './hooks/useKycSyncMutation';
import './index.scss';

function KycSyncButton() {
  const { sync } = useKycSyncMutation();

  return (
    <RoundedButton type="secondary" onClick={sync} className="kyc-sync-button">
      Sync KYC
    </RoundedButton>
  );
}

export default KycSyncButton;
