import { ethers } from 'ethers';
import { getUserLevelLabel, getAllocationModelName } from './helpers';
import { getDealStatusName } from 'models/dealStatusModel';

function decodePhase(abiEncodedResult) {
  if (!abiEncodedResult.length || abiEncodedResult === '0x') return;

  const endTimestamp = abiEncodedResult[8];

  return {
    minAccessLevel: abiEncodedResult[1],
    minViewLevel: abiEncodedResult[2],
    phaseEndTimestamp: +endTimestamp < 10 ** 20 ? endTimestamp : null,
    currentPhaseIndex: abiEncodedResult[10].toString(),
  };
}

function decodeDealInfo(abiEncodedResult) {
  if (!abiEncodedResult.length || abiEncodedResult === '0x') return;

  const intData = abiEncodedResult[2];

  return {
    dealSize: ethers.utils.formatUnits(intData[0], 'mwei'),
    circulatingSupply: ethers.utils.formatEther(intData[1]),
    minContribution: ethers.utils.formatUnits(intData[2], 'mwei'),
    minAccessLevel: intData[3].toNumber(),
    minAccessLevelLabel: getUserLevelLabel(intData[3].toNumber()),
    raisedAmount: ethers.utils.formatUnits(intData[4], 'mwei'),
    status: getDealStatusName(intData[5]),
    allocationModel: getAllocationModelName(intData[6].toString(), intData[8].toNumber()),
    userCap: ethers.utils.formatUnits(intData[7], 'mwei'),
    unlimitedTimestamp: intData[8].toNumber(),
    minViewLevel: intData[9].toNumber(),
    delayedMinAccessLevel: intData[10].toNumber(),
    whitelistEndTimestamp: intData[11].toNumber(),
  };
}

const decodeTokenInfo = (results, offset = 0) => {
  const name = results[offset]?.returnData?.[0] || 'Unknown';
  const symbol = results[offset + 1]?.returnData?.[0] || 'Unknown';
  const decimals = results[offset + 2]?.returnData?.[0] || 18;

  return { name, symbol, decimals };
};

export { decodeDealInfo, decodePhase, decodeTokenInfo };
