import { useNotifications } from 'hooks';
import { useMutation } from 'react-query';
import { syncKyc } from 'services/apiService';

function useKycSyncMutation() {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { mutateAsync: sync } = useMutation(syncKyc, {
    onSuccess: () => {
      showSuccessNotification('KYC data synced successfully');
    },
    onError: () => {
      showErrorNotification('Failed to sync KYC data');
    },
  });

  return { sync };
}

export default useKycSyncMutation;
